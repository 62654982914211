/*
	Landed by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/
/* Loader */
body.landing:before, body.landing:after {
  display: none !important;
}

/* Icon */
.icon.alt {
  color: inherit !important;
}

.icon.major.alt:before {
  color: #ffffff !important;
}

/* Banner */
#banner:after {
  background-color: rgba(23, 24, 32, 0.95);
}

/* Footer */
#footer .icons .icon.alt:before {
  color: #ffffff !important;
}
